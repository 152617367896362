<template>
  <div class="digitization">
    <div class="left-tree">
      <ul class="left-btn-tree">
        <li @click="() => selectTree(1)" :class="{'active': selectTreeActive === 1}">数据治理痛点</li>
        <li @click="() => selectTree(2)" :class="{'active': selectTreeActive === 2}">数据管理新范式</li>
        <li @click="() => selectTree(3)" :class="{'active': selectTreeActive === 3}">智能数据管理</li>
        <li @click="() => selectTree(4)" :class="{'active': selectTreeActive === 4}">Al2Data功能</li>
      </ul>
    </div>
    <div class="right-content">
      <div class="inner" id="right-content-id">
        <img id="img-1-id" class="img1" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-3-1.png" alt="">
        <img id="img-2-id" class="img2" style="margin-left: 40px; margin-bottom: 100px;" src="../../assets/website/m-3-2.png" alt="">
        <img id="img-3-id" class="img3" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-3-3.png" alt="">
        <img id="img-4-id" class="img4" style="margin-left: 30px; margin-bottom: 500px;" src="../../assets/website/m-3-4.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization3Component',
  components: {
  },
  mounted() {
    setTimeout(() => {
      const element = document.getElementById('right-content-id');
      const img1Id = document.getElementById('img-1-id')
      const img2Id = document.getElementById('img-2-id')
      const img3Id = document.getElementById('img-3-id')
      const img4Id = document.getElementById('img-4-id')
      if (element && img1Id && img2Id && img3Id && img4Id) {
        const img1Height = img1Id.offsetHeight + 100
        const img2Height = img2Id.offsetHeight + 100
        const img3Height = img3Id.offsetHeight + 100
        const img4Height = img4Id.offsetHeight + 100
        element.addEventListener('scroll', () => {
          const scrollTop = element.scrollTop;
          if (scrollTop >= 0 && scrollTop < img1Height) {
            this.selectTreeActive = 1;
          } else if (scrollTop >= img1Height && scrollTop < img1Height + img2Height) {
            this.selectTreeActive = 2;
          } else if (scrollTop >= img1Height + img2Height && scrollTop < img1Height + img2Height + img3Height) {
            this.selectTreeActive = 3;
          } else if (scrollTop >= img1Height + img2Height + img3Height && scrollTop < img1Height + img2Height + img3Height + img4Height) {
            this.selectTreeActive = 4;
          }
        });
      }
    }, 500);
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
    selectTree(v) {
      this.selectTreeActive = v;
      let height = 0
      for (let i = 1; i<= v - 1; i++) {
        const ele = document.getElementById(`img-${i}-id`)
        height += ele.offsetHeight
      }
      const element = document.getElementById('right-content-id');
      if (element) {
        element.scrollTo({
          top: height + 100 * (v - 1),
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    margin-top: 60px;
    .left-tree {
      float: left;
      width: 200px;
      min-height: 100px;
      margin-left: -220px;
      .left-btn-tree {
        // list-style: none;
        list-style-position: inside;
        padding-left: 0;
        margin-top: 0;
        li {
          transition: all 0.2s ease-in-out;
          user-select: none;
          color: #fff;
          margin: 9px 0;
          background: #000;
          border-radius: 4px;
          padding: 20px 20px;
          cursor: pointer;
          font-weight: bold;
          &:first-child {
            margin-top: 0;
          }
          &:hover {
            background: #307cff;
          }
          &.active {
            background: #307cff;
          }
        }
      }
    }
    .right-content {
      width: 100%;
      padding-left: 16px;
      min-height: 100px;
      border-top: 1px solid transparent;
      overflow-x: hidden;
      .inner {
        height: calc(100vh - 162px);
        overflow-y: auto;
        width: calc(100% + 17px);
        img {
          width: calc(100% - 100px);
          height: auto;
        }
      }
      // width: 100%;
      // padding-left: 16px;
      // min-height: 100px;
      // border-top: 1px solid transparent;
      // img {
      //   width: 100%;
      //   height: auto;
      // }
    }
  }
</style>
