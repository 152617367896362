<!--
 * @Author: ZSW
 * @Date: 2024/08/26 14:05:11
 * @LastEditTime: 2024/08/28 11:43:59
 * @Description: Do Something
-->
<template>
  <div class="website">
    <div class="website-header">
      <div class="inner">
        <div class="header-logo">
          <img class="logo" src="@/assets/website/web-logo.png" alt="">
        </div>
        <div class="header-menu-mb" @click="showNavFn" :class="{'show-nav': showNav}">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div v-if="showNav" class="header-menus">
          <ul class="header-menus-ul">
            <li @click="() => selectMenu(1)" :class="{'active': selectMenuActive === 1}">
              <div class="menu-title menu-title1">数字化</div>
              <div class="menu-title menu-title2">新范式</div>
            </li>
            <li @click="() => selectMenu(2)" :class="{'active': selectMenuActive === 2}">
              <div class="menu-title menu-title1">自动应用开发</div>
              <div class="menu-title menu-title2">Al2System</div>
            </li>
            <li @click="() => selectMenu(3)" :class="{'active': selectMenuActive === 3}">
              <div class="menu-title menu-title1">智能数据管理</div>
              <div class="menu-title menu-title2">Al2Data</div>
            </li>
            <li @click="() => selectMenu(4)" :class="{'active': selectMenuActive === 4}">
              <div class="menu-title menu-title1 menu-title-th">试用</div>
            </li>
            <li @click="() => selectMenu(5)" :class="{'active': selectMenuActive === 5}">
              <div class="menu-title menu-title1 menu-title-th">招聘</div>
            </li>
            <li @click="() => selectMenu(6)" :class="{'active': selectMenuActive === 6}">
              <div class="menu-title menu-title1 menu-title-th">公司介绍</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="website-body">
      <div class="inner">
        <digitization1 v-if="selectMenuActive === 1"></digitization1>
        <digitization2 v-if="selectMenuActive === 2"></digitization2>
        <digitization3 v-if="selectMenuActive === 3"></digitization3>
        <digitization4 v-if="selectMenuActive === 4"></digitization4>
        <digitization5 v-if="selectMenuActive === 5"></digitization5>
        <digitization6 v-if="selectMenuActive === 6"></digitization6>
      </div>
    </div>
  </div>
</template>

<script>
import digitization1 from './digitization1.vue';
import digitization2 from './digitization2.vue';
import digitization3 from './digitization3.vue';
import digitization4 from './digitization4.vue';
import digitization5 from './digitization5.vue';
import digitization6 from './digitization6.vue';
export default {
  name: 'MobileIndex',
  components: {
    digitization1,
    digitization2,
    digitization3,
    digitization4,
    digitization5,
    digitization6
  },
  data() {
    return {
      selectMenuActive: 1,
      isMobile: document.body.clientWidth <= 768 ? true : false,
      showNav: false
    }
  },
  methods: {
    selectMenu(v) {
      if (v === 4) {
        window.open(`https://marketing.cs-os.com/#/home/AICode`, '_blank');
      } else {
        this.selectMenuActive = v
      }
      this.showNav = !this.showNav
    },
    showNavFn() {
        this.showNav = !this.showNav
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.website {
  width: 100vw;
  height: 100vh;
  .website-header {
    height: 49px;
    background: #0e0e0e;
    border-bottom: 1px solid #404040;
    .inner {
      height: 100%;
      margin: 0 auto;
      .header-logo {
        float: left;
        width: 215px;
        margin-left: 8px;
        .logo {
          height: 32px;
          margin-top: 8px;
        }
      }
       .header-menu-mb {
            cursor: pointer;
            float: right;
            font-size: 0;
            line-height: 48px;
            text-align: center;
            transition: transform 0.3s;
            margin-right: 10px;
            margin-top: 18px;
        }
      .header-menu-mb span {
            background: #fff;
            border-radius: 3px;
            display: block;
            height: 2px;
            margin-bottom: 3px;
            -webkit-transform-origin: 20px;
            transform-origin: 16px;
            -webkit-transition: background .5s cubic-bezier(.77,.2,.05,1),opacity .55s ease,width .5s,-webkit-transform .5s cubic-bezier(.77,.2,.05,1);
            transition: background .5s cubic-bezier(.77,.2,.05,1),opacity .55s ease,width .5s,-webkit-transform .5s cubic-bezier(.77,.2,.05,1);
            transition: background .5s cubic-bezier(.77,.2,.05,1),opacity .55s ease,transform .5s cubic-bezier(.77,.2,.05,1),width .5s;
            transition: background .5s cubic-bezier(.77,.2,.05,1),opacity .55s ease,transform .5s cubic-bezier(.77,.2,.05,1),width .5s,-webkit-transform .5s cubic-bezier(.77,.2,.05,1);
            width: 18px
        }

        .header-menu-mb span:nth-last-child(2) {
            -webkit-transform: rotate(0);
            transform: rotate(0);
            -webkit-transition: width .5s ease;
            transition: width .5s ease
        }

        .header-menu-mb.show-nav span {
            opacity: 1;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
        }

        .header-menu-mb.show-nav span:nth-last-child(2) {
            opacity: 0;
            -webkit-transform: rotate(0);
            transform: rotate(0);
            -webkit-transition: width .5s;
            transition: width .5s;
            width: 0
        }

        .header-menu-mb.show-nav span:nth-last-child(3) {
            opacity: 1;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }
      .header-menus {
        position: absolute;
        top: 48px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 49px);
        background: #191a1c;
        z-index: 100;
        .header-menus-ul {
          list-style: none;
          justify-content: flex-end;
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 0;
          li {
            font-size: 14px;
            font-weight: 400;
            padding-top: 14px;
            flex: 1;
            user-select: none;
            color: #fff;
            // padding: 0 40px;
            cursor: pointer;
            text-align: center;
            transition: all 0.2s ease-in-out;
            .menu-title {
              font-weight: bold;
              &.menu-title-th {
                margin-top: 12px;
              }
            }
            &:hover {
              color: #307cfd;
            }
            &.active {
              color: #307cfd;
            }
            &:first-child, &:nth-child(2), &:nth-child(3) {
                height: 46px;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                height: 36px;
            }
          }
        }
      }
      
    }
  }
  .website-body {
    height: calc(100vh - 49px);
    overflow-y: auto;
    background: url('@/assets/website/bg.png') no-repeat center center/cover;
    background-color: #151617;
    .inner {
      // width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding-top: 30px;
    }
  }
} 
</style>
