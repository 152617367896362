<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:26:33
 * @LastEditTime: 2024/08/28 11:43:15
 * @Description: Do Something
-->
<template>
  <div class="digitization">
    <div class="right-content">
      <h4 class="title">公司简介</h4>
      <div
        style="
          color: rgb(255, 255, 255);
          margin-left: 10px;
          margin-right: 8px;
          text-indent: 2em;
          line-height: 38px;
          font-size: 14px;
          margin-bottom: 100px;
          text-align: left;
        "
      >
        <div>基石数智以领域模型为核心技术，结合华为企业数字化最佳实践，利用行业标杆企业高质量数据，打造企业级AI agent平台，帮助企业实现从数字化到AI化的跨越式发展，增强员工与数字生产力的协作，带来企业价值最大化的提升。</div>
        <div>作为企业级AI数字化服务商，能够基于企业领域私有数据，通过独有领域模型算法，打造行业领域大模型。并通过该模型为其提供包括业务应用自动生成、 数据智能管理、专家数字人在内的企业AI agent数字化解决方案。 </div>
        <div>核心团队由华为、阿里和腾讯业界精英组成，在领域模型、架构、数据、软件工程化和运营管理方面具有领先的理论和丰富的实战能力。</div>
      </div>

      <h4 class="title">公司布局</h4>
      <img class="img2" style="margin-bottom: 100px;" src="@/assets/website/m-6-1.png" alt="">

      <h4 class="title">管理层</h4>
      <img class="img3" style="margin-bottom: 100px;" src="@/assets/website/m-6-2.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization6Component',
  components: {
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
    selectTree(v) {
      this.selectTreeActive = v;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    .right-content {
      width: 100%;
      min-height: 100px;
      border-top: 1px solid transparent;
      text-align: center;
      img {
        width: 100%;
        height: auto;
      }
      .title {
        color: #fff;
        margin-bottom: 16px;
      }
    }
  }
</style>
