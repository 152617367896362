<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:55:14
 * @LastEditTime: 2024/08/28 16:56:10
 * @Description: Do Something
-->
<template>
  <div class="digitization">
    <div class="right-content">
      <div class="inner" id="right-content-id">
        <h4 class="title">软件开发4.0</h4>
        <img id="img-1-1-id" class="img1" style="margin-bottom: 100px;" src="@/assets/website/m-2-1.png" alt="">
        <img id="img-1-2-id" class="img2" style="margin-bottom: 100px;" src="@/assets/website/m-2-2.png" alt="">
        <img id="img-1-3-id" class="img3" style="margin-bottom: 100px;" src="@/assets/website/m-2-3.png" alt="">
        <img id="img-1-4-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-4.png" alt="">

        <h4 class="title">AlSystem特点</h4>
        <img id="img-2-1-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-5.png" alt="">
        <img id="img-2-2-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-6.png" alt="">

        <h4 class="title">全流程智能开发</h4>
        <img id="img-3-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-7.png" alt="">

        <h4 class="title">智能设计</h4>
        <img id="img-4-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-8.png" alt="">

        <h4 class="title">L3级开发自动化</h4>
        <img id="img-5-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-2-9.png" alt="">

        <h4 class="title">演示视频</h4>
        <video id="img-6-id" class="a7" width="90%" height="auto" controls style="margin-bottom: 100px;">
          <source src="@/assets/website/m-2-6.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization12Component',
  components: {
  },
  
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    .right-content {
      width: 100%;
      min-height: 100px;
      border-top: 1px solid transparent;
      overflow-x: hidden;
      .inner {
        text-align: center;
        height: calc(100vh - 162px);
        overflow-y: auto;
        img {
          width: calc(100% - 100px);
          height: auto;
        }
        .title {
          color: #fff;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
