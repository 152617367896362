<template>
  <div class="digitization">
    <div class="right-content">
      <div class="inner" id="right-content-id">
        <h4 class="title">数据治理痛点</h4>
        <img id="img-1-id" class="img1" style="margin-bottom: 100px;" src="@/assets/website/m-3-1.png" alt="">

        <h4 class="title">数据管理新范式</h4>
        <img id="img-2-id" class="img2" style="margin-bottom: 100px;" src="@/assets/website/m-3-2.png" alt="">

        <h4 class="title">智能数据管理</h4>
        <img id="img-3-id" class="img3" style="margin-bottom: 100px;" src="@/assets/website/m-3-3.png" alt="">

        <h4 class="title">Al2Data功能</h4>
        <img id="img-4-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-3-4.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization3Component',
  components: {
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    .right-content {
      width: 100%;
      min-height: 100px;
      border-top: 1px solid transparent;
      overflow-x: hidden;
      .inner {
        text-align: center;
        height: calc(100vh - 162px);
        overflow-y: auto;
        img {
          width: calc(100% - 100px);
          height: auto;
        }
        .title {
          color: #fff;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
