<template>
  <div v-if="isMobile" class="isMobile">
    <MobileIndex></MobileIndex>
  </div>
  <div v-else class="website">
    <div class="website-header">
      <div class="inner">
        <div class="header-logo">
          <img class="logo" src="../assets/website/web-logo.png" alt="">
        </div>
        <div class="header-menus">
          <ul class="header-menus-ul">
            <li @click="() => selectMenu(1)" :class="{'active': selectMenuActive === 1}">
              <div class="menu-title menu-title1">数字化</div>
              <div class="menu-title menu-title2">新范式</div>
            </li>
            <li @click="() => selectMenu(2)" :class="{'active': selectMenuActive === 2}">
              <div class="menu-title menu-title1">自动应用开发</div>
              <div class="menu-title menu-title2">Al2System</div>
            </li>
            <li @click="() => selectMenu(3)" :class="{'active': selectMenuActive === 3}">
              <div class="menu-title menu-title1">智能数据管理</div>
              <div class="menu-title menu-title2">Al2Data</div>
            </li>
            <li @click="() => selectMenu(4)" :class="{'active': selectMenuActive === 4}">
              <div class="menu-title menu-title1 menu-title-th">试用</div>
            </li>
            <li @click="() => selectMenu(5)" :class="{'active': selectMenuActive === 5}">
              <div class="menu-title menu-title1 menu-title-th">招聘</div>
            </li>
            <li @click="() => selectMenu(6)" :class="{'active': selectMenuActive === 6}">
              <div class="menu-title menu-title1 menu-title-th">公司介绍</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="website-body">
      <div class="inner">
        <digitization1 v-if="selectMenuActive === 1"></digitization1>
        <digitization2 v-if="selectMenuActive === 2"></digitization2>
        <digitization3 v-if="selectMenuActive === 3"></digitization3>
        <digitization4 v-if="selectMenuActive === 4"></digitization4>
        <digitization5 v-if="selectMenuActive === 5"></digitization5>
        <digitization6 v-if="selectMenuActive === 6"></digitization6>
      </div>
    </div>
  </div>
</template>

<script>
import digitization1 from './components/digitization1.vue';
import digitization2 from './components/digitization2.vue';
import digitization3 from './components/digitization3.vue';
import digitization4 from './components/digitization4.vue';
import digitization5 from './components/digitization5.vue';
import digitization6 from './components/digitization6.vue';
import MobileIndex from './components/mobile/index.vue';
export default {
  name: 'HelloWorld',
  components: {
    digitization1,
    digitization2,
    digitization3,
    digitization4,
    digitization5,
    digitization6,
    MobileIndex
  },
  data() {
    return {
      selectMenuActive: 1,
      isMobile: document.body.clientWidth <= 768 ? true : false
    }
  },
  methods: {
    selectMenu(v) {
      if (v === 4) {
        window.open(`https://marketing.cs-os.com/#/home/AICode`, '_blank');
      } else {
        this.selectMenuActive = v
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.website {
  width: 100vw;
  height: 100vh;
  .website-header {
    height: 70px;
    background: #0e0e0e;
    border-bottom: 1px solid #404040;
    .inner {
      // width: 1200px;
      width: 64%;
      height: 100%;
      margin: 0 auto;
      .header-logo {
        float: left;
        width: 215px;
        .logo {
          height: 55px;
          margin-top: 8px;
        }
      }
      .header-menus {
        float: right;
        width: calc(100% - 215px);
        .header-menus-ul {
          list-style: none;
          display: flex;
          justify-content: flex-end;
          height: 70px;
          margin-top: 0;
          margin-bottom: 0;
          li {
            height: 70px;
            padding-top: 14px;
            flex: 1;
            user-select: none;
            float: left;
            color: #fff;
            // padding: 0 40px;
            cursor: pointer;
            text-align: center;
            transition: all 0.2s ease-in-out;
            .menu-title {
              font-weight: bold;
              &.menu-title-th {
                margin-top: 12px;
              }
            }
            &:hover {
              color: #307cfd;
            }
            &.active {
              color: #307cfd;
            }
            &:first-child {
              flex: 0.12;
            }
            &:nth-child(2) {
              flex: 0.17;
            }
            &:nth-child(3) {
              flex: 0.17;
            }
            &:nth-child(4) {
              flex: 0.12;
            }
            &:nth-child(5) {
              flex: 0.12;
            }
            &:nth-child(6) {
              flex: 0.12;
            }
          }
        }
      }
      
    }
  }
  .website-body {
    height: calc(100vh - 71px);
    overflow-y: auto;
    background: url('../assets/website/bg.png') no-repeat center center/cover;
    background-color: #151617;
    .inner {
      // width: 1200px;
      width: 64%;
      margin: 0 auto;
      padding-top: 30px;
    }
  }
} 
</style>
