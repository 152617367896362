<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:55:14
 * @LastEditTime: 2024/08/28 12:33:02
 * @Description: Do Something
-->
<template>
  <div class="digitization">
    <div class="left-tree">
      <ul class="left-btn-tree">
        <li @click="() => selectTree(1)" :class="{'active': selectTreeActive === 1}">软件开发4.0</li>
        <li @click="() => selectTree(2)" :class="{'active': selectTreeActive === 2}">AlSystem特点</li>
        <li @click="() => selectTree(3)" :class="{'active': selectTreeActive === 3}">全流程智能开发</li>
        <li @click="() => selectTree(4)" :class="{'active': selectTreeActive === 4}">智能设计</li>
        <li @click="() => selectTree(5)" :class="{'active': selectTreeActive === 5}">L3级开发自动化</li>
        <li @click="() => selectTree(6)" :class="{'active': selectTreeActive === 6}">演示视频</li>
      </ul>
    </div>
    <div class="right-content">
      <div class="inner" id="right-content-id">
        <img id="img-1-1-id" class="img1" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-1.png" alt="">
        <img id="img-1-2-id" class="img2" style="margin-left: 37px; margin-bottom: 100px;" src="../../assets/website/m-2-2.png" alt="">
        <img id="img-1-3-id" class="img3" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-3.png" alt="">
        <img id="img-1-4-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-4.png" alt="">

        <img id="img-2-1-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-5.png" alt="">
        <img id="img-2-2-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-6.png" alt="">

        <img id="img-3-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-7.png" alt="">
        <img id="img-4-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-8.png" alt="">
        <img id="img-5-id" class="img4" style="margin-left: 30px; margin-bottom: 100px;" src="../../assets/website/m-2-9.png" alt="">

        <video id="img-6-id" height="auto" controls style="margin-left: 30px; margin-bottom: 500px;">
          <source src="../../assets/website/m-2-6.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization12Component',
  components: {
  },
  mounted() {
    setTimeout(() => {
      const element = document.getElementById('right-content-id');
      const img11Id = document.getElementById('img-1-1-id')
      const img12Id = document.getElementById('img-1-2-id')
      const img13Id = document.getElementById('img-1-3-id')
      const img14Id = document.getElementById('img-1-4-id')

      const img21Id = document.getElementById('img-2-1-id')
      const img22Id = document.getElementById('img-2-2-id')

      const img3Id = document.getElementById('img-3-id')
      const img4Id = document.getElementById('img-4-id')
      const img5Id = document.getElementById('img-5-id')
      const img6Id = document.getElementById('img-6-id')

      if (element && img11Id && img12Id && img13Id && img14Id && img21Id && img22Id && img3Id && img4Id && img5Id) {
        const img1Height = img11Id.offsetHeight + img12Id.offsetHeight + img13Id.offsetHeight + img14Id.offsetHeight + 400
        const img2Height = img21Id.offsetHeight + img22Id.offsetHeight + 200
        const img3Height = img3Id.offsetHeight + 100
        const img4Height = img4Id.offsetHeight + 100
        const img5Height = img5Id.offsetHeight + 100
        const img6Height = img6Id.offsetHeight + 200
        
        element.addEventListener('scroll', () => {
          const scrollTop = element.scrollTop;
          if (scrollTop >= 0 && scrollTop < img1Height) {
            this.selectTreeActive = 1;
          } else if (scrollTop >= img1Height && scrollTop < img1Height + img2Height) {
            this.selectTreeActive = 2;
          } else if (scrollTop >= img1Height + img2Height && scrollTop < img1Height + img2Height + img3Height) {
            this.selectTreeActive = 3;
          } else if (scrollTop >= img1Height + img2Height + img3Height && scrollTop < img1Height + img2Height + img3Height + img4Height) {
            this.selectTreeActive = 4;
          } else if (scrollTop >= img1Height + img2Height + img3Height + img4Height && scrollTop < img1Height + img2Height + img3Height + img4Height + img5Height) {
            this.selectTreeActive = 5;
          } else if (scrollTop >= img1Height + img2Height + img3Height + img4Height + img5Height && scrollTop < img1Height + img2Height + img3Height + img4Height + img5Height +img6Height) {
            this.selectTreeActive = 6;
          }
        });
      }
    }, 500);
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
    selectTree(v) {
      this.selectTreeActive = v;
      const img11Id = document.getElementById('img-1-1-id')
      const img12Id = document.getElementById('img-1-2-id')
      const img13Id = document.getElementById('img-1-3-id')
      const img14Id = document.getElementById('img-1-4-id')

      const img21Id = document.getElementById('img-2-1-id')
      const img22Id = document.getElementById('img-2-2-id')

      const img3Id = document.getElementById('img-3-id')
      const img4Id = document.getElementById('img-4-id')
      const img5Id = document.getElementById('img-5-id')
      
      const img1Height = img11Id.offsetHeight + img12Id.offsetHeight + img13Id.offsetHeight + img14Id.offsetHeight + 400
      const img2Height = img21Id.offsetHeight + img22Id.offsetHeight + 200
      const img3Height = img3Id.offsetHeight + 100
      const img4Height = img4Id.offsetHeight + 100
      const img5Height = img5Id.offsetHeight + 100
      const element = document.getElementById('right-content-id');
      if (element) {
        if (v === 1) {
          element.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        } else if (v === 2) {
          element.scrollTo({
            top: img1Height,
            behavior: 'smooth'
          });
        } else if (v === 3) {
          element.scrollTo({
            top: img1Height + img2Height,
            behavior: 'smooth'
          });
        } else if (v === 4) {
          element.scrollTo({
            top: img1Height + img2Height + img3Height,
            behavior: 'smooth'
          });
        } else if (v === 5) {
          element.scrollTo({
            top: img1Height + img2Height + img3Height + img4Height + 20,
            behavior: 'smooth'
          });
        } else if (v === 6) {
          element.scrollTo({
            top: img1Height + img2Height + img3Height + img4Height + img5Height + 50,
            behavior: 'smooth'
          });
        }
        
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    margin-top: 60px;
    .left-tree {
      float: left;
      width: 200px;
      min-height: 100px;
      margin-left: -220px;
      .left-btn-tree {
        // list-style: none;
        list-style-position: inside;
        padding-left: 0;
        margin-top: 0;
        li {
          transition: all 0.2s ease-in-out;
          user-select: none;
          color: #fff;
          margin: 9px 0;
          background: #000;
          border-radius: 4px;
          padding: 20px 20px;
          cursor: pointer;
          font-weight: bold;
          &:first-child {
            margin-top: 0;
          }
          &:hover {
            background: #307cff;
          }
          &.active {
            background: #307cff;
          }
        }
      }
    }
    .right-content {
      width: 100%;
      padding-left: 16px;
      min-height: 100px;
      border-top: 1px solid transparent;
      overflow-x: hidden;
      .inner {
        height: calc(100vh - 162px);
        overflow-y: auto;
        width: calc(100% + 17px);
        img {
          width: calc(100% - 100px);
          height: auto;
        }
        #img-6-id {
          width: calc(100% - 30px);
        }
      }
    }
  }
</style>
