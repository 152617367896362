<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:55:12
 * @LastEditTime: 2024/08/26 14:54:51
 * @Description: Do Something
-->
<template>
  <div class="digitization">
    <div class="right-content">
      <div class="inner" id="right-content-id">
        <h4 class="title">数字化转型障碍</h4>
        <img id="img1-id" class="img1" style="margin-bottom: 100px;" src="@/assets/website/m-1-1.png" alt="">

        <h4 class="title">新范式</h4>
        <img id="img2-id" class="img2" style="margin-bottom: 100px;" src="@/assets/website/m-1-2.png" alt="">

        <h4 class="title">加速数字化转型</h4>
        <img id="img3-id" class="img3" style="margin-bottom: 100px;" src="@/assets/website/m-1-3.png" alt="">
        
        <h4 class="title">产品与解决方案</h4>
        <img id="img4-id" class="img4" style="margin-bottom: 100px;" src="@/assets/website/m-1-4.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization1Component',
  components: {
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    .right-content {
      width: 100%;
      min-height: 100px;
      border-top: 1px solid transparent;
      overflow-x: hidden;
      .inner {
        text-align: center;
        height: calc(100vh - 162px);
        overflow-y: auto;
        img {
          width: calc(100% - 100px);
          height: auto;
        }
        .title {
          color: #fff;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
