<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:55:13
 * @LastEditTime: 2024/08/28 11:54:10
 * @Description: Do Something
-->
<template>
	<div class="digitization">
		<div class="right-content">
			<div class="inner" id="right-content-id">
				<div id="img-i-id" style="color: #fff; margin-bottom: 100px;">
					<h4 style="color: #fff; font-size: 18px">AI算法工程师</h4>
					<span style="font-size: 14px">职位描述</span>

					<p>1. 负责大规模语言模型的集成与优化，提升模型在特定应用场景下的性能和效率。</p>
					<p>2. 设计并实施基于AI大模型的创新应用，包括但不限于自然语言处理、对话系统、内容生成、智能推荐等。</p>
					<p>3. 与产品经理、业务人员紧密合作，理解业务需求，设计并实现高效的AI解决方案。</p>
					<p>4. 负责AI模型的训练、调优和部署，确保模型性能达到最优。</p>
					<p>5. 跟踪最新的AI技术发展，评估并引入新技术以提升产品竞争力。</p>
					<p>6. 解决开发过程中遇到的技术难题，并提出创新的解决方案。</p>
				</div>
				<div id="img-2-id" style="color: #fff;">
					<h4 style="color: #fff; font-size: 18px">大模型调优高级工程师</h4>
					<span style="font-size: 14px">岗位职责：</span>
					<p>
						1. 负责开发和维护AI Agent框架，构建模块化RAG流程，包括训练流程搭建、指令微调、Prompt工程以及面向下游任务的效果优化等。
					</p>
					<p>2. 负责基于通用LLM构建行业垂直LLM人工智能知识架构体系建设和研发。</p>
					<br />
					<span style="font-size: 14px">岗位要求：</span>
					<p>1. 计算机科学或相关领域的本科及以上学历，掌握数据结构、操作系统、算法等相关专业知识;</p>
					<p>2. 掌握Python编程语言，熟悉Linux环境开发，有2年以上服务端开发经验，有良好的编程习惯；</p>
					<p>3. 了解机器学习、深度学习算法、熟悉主流的LLM框架；</p>
					<p>4. 具有AI应用开发经验优化，有langchain, llamaIndex, SemanticKernel等工程经验优化</p>
					<p>5. 具备较强的团队协作能力和沟通能力，有较强的学习能力和业务分析及问题解决能力； 能够适应不定期出差工作。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Digitization5Component',
	components: {},
	data() {
		return {
			selectTreeActive: 1
		}
	},
	methods: {
		selectTree(v) {
			this.selectTreeActive = v
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.digitization {
	.right-content {
		position: relative;
		top: -4px;
		width: 100%;
		min-height: 100px;
		border-top: 1px solid transparent;
		p {
			font-size: 14px;
			padding: 6px 0;
		}
		img {
			width: 100%;
			height: auto;
		}
		width: calc(100% - 16px);
		padding-left: 10px;
		padding-right: 10px;
		min-height: 100px;
		border-top: 1px solid transparent;
		overflow-x: hidden;
		.inner {
			height: calc(100vh - 162px);
			overflow-y: auto;
			img {
				width: calc(100% - 100px);
				height: auto;
			}
		}
	}
}
</style>
