<!--
 * @Author: ZSW
 * @Date: 2024/08/21 11:55:13
 * @LastEditTime: 2024/08/22 10:07:07
 * @Description: Do Something
-->
<template>
  <div class="digitization">
    <div class="apply" @click="apply">
      试&nbsp;用
    </div>
  </div>
</template>

<script>
export default {
  name: 'Digitization4Component',
  components: {
  },
  data() {
    return {
      selectTreeActive: 1
    }
  },
  methods: {
    apply() {
      window.open(`https://marketing.cs-os.com/`, '_blank');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .digitization {
    margin-top: 60px;
    .apply {
      transition: all 0.2s ease-in-out;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      color: #fff;
      margin: 20px 0;
      background: #000;
      border-radius: 4px;
      padding: 15px 20px;
      cursor: pointer;
      font-weight: bold;
      width: 124px;
      text-align: center;
      margin: 0 auto;
      margin-top: calc(100vh/2 - 100px);
      &:hover {
        background: #307cff;
      }
    }
    .left-tree {
      float: left;
      width: 200px;
      min-height: 100px;
      margin-left: -220px;
      .left-btn-tree {
        // list-style: none;
        list-style-position: inside;
        padding-left: 0;
        margin-top: 0;
        li {
          transition: all 0.2s ease-in-out;
          user-select: none;
          color: #fff;
          margin: 9px 0;
          background: #000;
          border-radius: 4px;
          padding: 20px 20px;
          cursor: pointer;
          font-weight: bold;
          &:first-child {
            margin-top: 0;
          }
          &:hover {
            background: #307cff;
          }
          &.active {
            background: #307cff;
          }
        }
      }
    }
    .right-content {
      width: 100%;
      padding-left: 16px;
      min-height: 100px;
      border-top: 1px solid transparent;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>
